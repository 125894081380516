import apis from '../Services/api-services';

export const colorsEnums = {
  error: '#A03C42',
};

export const formatMessage = (text) => {
  if (text) {
    const formattedText = text
      .replace(/^####\s(.+)$/gm, '<h4>$1</h4>') // Format level 4 headings
      .replace(/^###\s(.+)$/gm, '<h3>$1</h3>') // Format level 3 headings
      .replace(/^##\s(.+)$/gm, '<h2>$1</h2>') // Format level 2 headings
      .replace(/^#\s(.+)$/gm, '<h1>$1</h1>') // Format level 1 headings
      .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>') // Bold text
      .replace(/^-\s(.+)$/gm, '<li>$1</li>') // Bullet points
      .replace(/\n/g, '<br />'); // Line breaks
    return formattedText;
  }
};

export const handleProfileRedirection = async (navigate, fromScreen) => {
  const { data: { profile } = {} } =
    await apis.agent.getAgentProfileCompletionStatus();

  if (!profile) return;

  const { is_part1_complete, is_verification_initiated } = profile;

  if (!is_part1_complete || !is_verification_initiated) {
    navigate('/profile-onboarding');
  } else if (is_part1_complete && !is_verification_initiated) {
    navigate('/image-verification');
  } else if (is_part1_complete && is_verification_initiated) {
    navigate('/profile');
  }
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
