import React, { cloneElement } from "react";
import vREFYD_PURPLE from "../Assets/images/vREFYD_purple.png";

import { useNavigate } from "react-router-dom";
import { LOGIN } from "../constants/route-path";
import "./loginWrapper.css";
import AlertMessage from "./alertmessage";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LOGINBG from "../Assets/images/login-vrefyd-bg.png";
import { Box, Typography } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

const LoginWrapperNew = (props) => {
  const {
    showBack = false,
    openSnackbar,
    setOpenSnackbar,
    setAlertmsg,
    alertType,
    alertMsg,
  } = props;

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height:'100vh',
        display: "flex",
        flexDirection:{
            xs: "column",  
            xmd: "row",  
        },
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",  
            xmd: "60%",   
        },
          height: "100vh",
          padding: "15px",
          position: "relative",
          borderRadius:'40px',
          display: {
            xs: "none",  
            xmd: "block",   
        },
        }}
      >
        <img
          src={LOGINBG}
          style={{ width: "100%", height: "100%" , objectFit:'cover',
          borderRadius:'40px'
           }}
          alt="vrefyd"
        />
        <Box
          sx={{
            position: "absolute",
            bottom: {
                xs:"35px",
                xmd:"55px",
            },
            left: {
                xs:"25px",
                xmd:"55px",
            },
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
            }}
          >
            You’re Not Just a Part of the System.
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
            }}
          >
            {" "}
            You Are The System!
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            Join India’s most trusted real estate community
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: {
            xs: "100%",  
            xmd: "40%",   
          },
          height:'100vh',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={vREFYD_PURPLE}
          alt="vREfyd-logo"
          width={130}
          style={{ marginBottom: "40px" }}
        />
        <Box
          sx={{
            width: {
                xs:'92%',
                xmd:"75%"
            } ,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 15.5px 0px rgba(168, 77, 249, 0.2)",
            padding: "15px",
            borderRadius: "20px",
            minHeight: "290px",
            position: "relative",
          }}
        >
          {showBack && (
            <KeyboardBackspaceRoundedIcon
              onClick={() => navigate(LOGIN)}
              sx={{
                position: "absolute",
                cursor: "pointer",
                top: 20,
                left: 10,
                color: "#7D7D7D",
                strokeWidth: "1px",
              }}
            />
          )}

          {cloneElement(props.children, { ...props })}
        </Box>
        {showBack && (
          <Typography
            sx={{ color: "#7D7D7D", fontSize: "14px", marginTop: "20px" }}
          >
            Please check WhatsApp/SMS for verification code
          </Typography>
        )}

        <Typography
          sx={{
            color: "#600DB4",
            fontSize: "14px",
            position: "absolute",
            bottom: 15,
          }}
        >
          You're currently using an internal Beta version.
        </Typography>
      </Box>
      <AlertMessage
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        setAlertmsg={setAlertmsg}
        alertType={alertType}
        alertMsg={alertMsg}
      />
    </Box>
  );
};

export default LoginWrapperNew;
