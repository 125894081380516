export const SESSION_EXPIRY = "Session Expired!";
export const INTERNAL_ERROR = "Internal server error!";
export const SERVER_ERROR = "Server can't be reached!";
export const NO_ACCESS_TO_THE_PAGE = "Forbidden!";

export const APP_VERSION = "app31625";
export const GOOGLE_MAPS_API_KEY = "AIzaSyBLkczqTV5kn8HrJcwpJF-IsSBs9cwauxk";

export const SOCKETURL = `wss://dev-aira-apis.vrefyd.com/ws/chat/?token=`;
// export const SOCKETURL = `wss://aira-apis.vrefyd.com/ws/chat/?token=`;

export const LOCAL_STORAGE_DATA = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  USER: "userDetails",
  APP_VERSION: "app-version",
};

export const ALERT_TYPES = {
  info: "info",
  error: "error",
  success: "success",
  warning: "warning",
};

export const RENTVALUE = "Rent";
export const SALEVALUE = "Purchase";
export const RENTVALUEADDPROPERTY = "For Rent";
export const SALEVALUEADDPROPERTY = "For Sale";

export const minSaleValue = 1000000;
export const maxSaleValue = 1000000000;
export const minRentValue = 10000;
export const maxRentValue = 1000000;
