import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton, Avatar, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./property-verification-modal.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import apis from "../Services/api-services";

const ProfileCardSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        width:'100%',
        gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
        gap: '16px',
        padding: '16px',
      }}
    >
      {/* Skeleton Cards */}
      {Array.from({ length: 6 }).map((_, index) => (
        <Box
          key={index}
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            textAlign: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* Profile Image Skeleton */}
          <Skeleton
            variant="circular"
            width={64}
            height={64}
            sx={{ margin: '0 auto', marginBottom: '8px' }}
          />
          {/* Name Skeleton */}
          <Skeleton
            variant="text"
            width="60%"
            height={20}
            sx={{ margin: '0 auto', marginBottom: '4px' }}
          />
          {/* Badge Skeleton */}
          <Skeleton
            variant="text"
            width="30%"
            height={16}
            sx={{ margin: '0 auto' }}
          />
        </Box>
      ))}
    </Box>
  );
};

const PropertyDealClosure = ({ onClose, propertyId ,closeDrawer ,fetchListedProperties }) => {
  const [leadsList, setLeadsList] = useState([]);
  const [selectedLead,setSelectedLead] =  useState('')
  const [selectedLeadName,setSelectedLeadName] =  useState('')
  const [loading,setLoading] =  useState(false)
  const [submitloading,setsubmitLoading] =  useState(false)
  const [dealClosed,setDealClosed] =  useState(false)


  useEffect(() => {
    getLeadsOnProperty();
  }, []);

  const getLeadsOnProperty = () => {
    setLoading(true)
    apis.property
      .getLeadsOnProperty(propertyId)
      .then((res) => {
    setLoading(false)

        console.log(res, "ssnmbsjk");
        if (res?.status === 200) {
          if (res?.data?.leads) {
            setLeadsList(res?.data?.leads);
          } else {
            setLeadsList([]);
          }
        } else {
        }
      })
      .catch((err) => {
    setLoading(false)
        console.log(err);
      });
  };

  const closeDeal = () => {
    setsubmitLoading(true)
    apis.property
      .markDealClosure(propertyId,{
        closed_with_id : selectedLead,
        closed_with_name: selectedLeadName
      })
      .then((res) => {
        setsubmitLoading(false)
        if (res?.status === 200) {
         setDealClosed(true)
         setTimeout(()=>{
          fetchListedProperties && fetchListedProperties()
         },3000)
        } else {
        }
      })
      .catch((err) => {
        setsubmitLoading(false)
        console.log(err);
      });
  };



  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        maxWidth: "100%",
        height:'100%',
        borderRadius: 2,
        outline: "none",
        // height: '80%',
        overflow: "scroll",
        // margin: 2,
        position: "relative",
      }}
    >
      {dealClosed ? <Box sx={{ p: 3, margin: "auto" , minHeight:'90vh',display:'flex',justifyContent:'center',alignItems:'center' }}>
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          flexDirection:'column'
        }}>
        <VerifiedIcon
                        sx={{
                          color: "#600DB4",
                          width: "68px",
                          height:'65px'
                        }}
                      />
                      <Typography sx={{
                        color:'#474747',
                        fontSize:'18px',
                        mt:1

                      }}>Deal closed successfully.</Typography>
        </Box>
      </Box> : <Box sx={{ p: 3, margin: "auto" , minHeight:'90vh' }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <ArrowBackIcon
            onClick={() => onClose()}
            sx={{
              border: "1px solid #D2D2D2",
              borderRadius: "4px",
              padding: "4px",
              fontSize: "2rem",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{
              padding:'20px'
        }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              color: "#474747",
              fontSize: "16px",
              fontWeight: "500",
              mb:2
         
            }}
          >
            Deal Closure
          </Typography>
          <Typography
          sx={{
            color: "#474747",
            fontSize: "18px",
            fontWeight: "500",
            my:2
          }}>Please select who you closed the deal with</Typography>
          <Typography sx={{
            color:'#7D7D7D',
            fontSize: "15px",
            fontWeight: "400",
            width:'87%'
          }}>
            They will be notified and will need to confirm that this deal was
            closed with them
          </Typography>
          <Typography sx={{
            fontSize:'16px',
            my:2
          }}>
            These are the users who have unlocked this property{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              maxHeight:'55vh',
              overflow:'scroll',
              justifyContent:'flex-start',
              alignItems:'flex-start'
            }}
          >
            {loading ? 
            <ProfileCardSkeleton /> :
          
            leadsList?.map((item) => {
              return (
                <Box
                onClick={()=>{
                  setSelectedLead(item.lead_id)
                  setSelectedLeadName(item.lead_name)
                }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    margin: "10px",
                    border: `${selectedLead === item.lead_id ? '2px':'1.12px'} solid ${selectedLead === item.lead_id ? '#600DB4': 'rgba(210, 210, 210, 1)'}`,
                    borderRadius: "10px",
                    width: "140px",
                    height: "140px",
                    cursor:'pointer'
                  }}
                >
                  {!item.is_custom && <Avatar
                    alt={item.lead_name}
                    src={item.profile_picture}
                    sx={{ width: 55, height: 55 }}
                  />}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    {item.is_custom ? 
                    <Typography sx={{
                      color: "#474747",
                      fontSize: "15px",
                      fontWeight: 500,
                      textAlign:'center'
                    }}>
                The deal was closed outside <Typography sx={{color:'#600DB4',fontWeight:600}}>vREfyd</Typography>
                    </Typography>:
                    <Typography
                      sx={{
                        color: "#474747",
                        fontSize: "15px",
                        fontWeight: 500,
                         textAlign:'center'

                      }}
                    >
                      {item.lead_name}
                    </Typography>}
                    {item.is_verified && (
                      <VerifiedIcon
                        sx={{
                          color: "#600DB4",
                          marginLeft: ".5rem",
                          width: "18px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              );
            })
          }
          </Box>
        </Box>
      </Box>}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position:'-webkit-sticky',
          bottom: 0,
          left: 0,
          width: "100%",
          height: "70px",
          background: "#fff",
          zIndex: 99,
        }}
      >
        <Button
          type="submit"
          color="primary"
          variant={`${dealClosed ? '':'contained'}`}
          disabled={!selectedLeadName || submitloading}
          sx={{ textTransform: "capitalize", width: 120 }}
          onClick={() => dealClosed ? closeDrawer(false) :  closeDeal()}
        >
          {dealClosed? 'Close': submitloading ? 'Loading...':'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default PropertyDealClosure;
