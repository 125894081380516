import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
} from '@mui/material';
import React from 'react';
import { colorsEnums } from '../constants/enums';

export default function RadioSelect({
  name = '',
  value = '',
  dropdownValues = [],
  setFieldValue = () => {},
  required = false,
  label = '',
  helperText = '',
  multiple = false,
  hide = false,
}) {
  if (hide) {
    return null;
  }

  return (
    <FormControl sx={{ width: '100%', marginBottom: '.5rem' }}>
      <FormLabel sx={{ margin: '12px 0' }}>
        {label}{' '}
        {required && <span style={{ color: colorsEnums.error }}>*</span>}
      </FormLabel>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {dropdownValues?.map((item) => (
          <FormControlLabel
            key={item.id}
            sx={{
              '& .MuiButtonBase-root': {
                display: 'none !important',
              },
              border: '1px solid #600DB4',
              borderRadius: '8px',
              margin: '0rem 1rem 1rem 0 !important',
              padding: '6px 1rem',
              background:
                multiple && value.includes(item.value)
                  ? '#F5EDFF'
                  : value === item.value
                  ? '#F5EDFF'
                  : '#fff',
              color: '#474747',
              textTransform: 'capitalize',
              fontSize: '16px',
              fontFamily: 'Inter',
            }}
            control={
              multiple ? (
                <Checkbox
                  checked={value}
                  onChange={() => {
                    if (value.includes(item.value)) {
                      if (name === 'areasOfOperation') {
                        setFieldValue(item.value);
                      } else {
                        setFieldValue(
                          name,
                          value?.filter((el) => el !== item.value)
                        );
                      }
                    } else {
                      if (name === 'areasOfOperation') {
                        setFieldValue(item.value);
                      } else {
                        setFieldValue(name, [...value, item.value]);
                      }
                    }
                  }}
                />
              ) : (
                <Radio
                  checked={value === item.value}
                  onChange={() => setFieldValue(name, item.value)}
                />
              )
            }
            label={item.value}
          />
        ))}
      </Box>

      {helperText && (
        <FormHelperText sx={{ color: '#bf3333' }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
