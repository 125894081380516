import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import Cropper from "react-easy-crop";
import getCroppedImg from "./getCroppedImg";

export default function ImageCropperl({ open, onClose, onCropSave, aspect }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async (image) => {
    try {
      const croppedResult = await getCroppedImg(image, croppedAreaPixels);
      onCropSave(croppedResult);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "60%",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Cropper
            image={open.image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              height: "100%",
              marginTop: 12,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSave(open.image)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
