import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomChip from '../../components/customChip';
import YEARSICON from '../../Assets/images/years-bag.svg';
import DEALSICON from '../../Assets/images/deals-key.svg';
import VREFYDTICK from '../../Assets/images/success-tick.png';
import {
  numDifferentiation,
  renderAreaOfOperatioChips,
} from '../../Utils/helperUtils';
import Slider from 'react-slick';
import DEFAULTIMG from '../../Assets/images/demo2.png';
import { SALEVALUE } from '../../constants/app-constants';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import PropertyDetail from '../../components/propertyDetail';
import ProfileDetail from '../../components/profileDetails';

const CustomArrow = ({ className, onClick, style, direction }) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent', // No background color
        borderRadius: '50%',
        zIndex: 10,
        cursor: 'pointer',
        color: 'black', // Icon color
        width: '40px', // Size of the arrow container
        height: '40px',
      }}
    >
      {/* Render the appropriate icon */}
      {direction === 'left' ? (
        <ChevronLeftIcon fontSize="large" />
      ) : (
        <ChevronRightIcon fontSize="large" />
      )}
    </div>
  );
};

const PropertyListingItem = (props) => {
  const navigate = useNavigate();
  const { item, city, locality } = props;

  const [selectedPropertyID, setSelectedProperty] = useState();
  const sliderRef = React.useRef(null);
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);
  const [openProfileDetails, setOpenProfileDetails] = useState(false);
  const [selectedBrokerProfile, setSelectedBrokerProfile] = useState('');

  const settings = {
    dots: true,
    infinite: false, // Ensures it stops at the ends
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: (
      <CustomArrow className="custom-arrow slick-next" direction="right" />
    ),
    prevArrow: (
      <CustomArrow className="custom-arrow slick-prev" direction="left" />
    ),
  };

  const handlePropertyClick = (prop, cust) => {
    setSelectedProperty(prop);
    setSelectedBrokerProfile(cust);
    setOpenPropertyDetail(true);
  };

  const toggleProfileDrawer = (newOpen) => () => {
    setOpenProfileDetails(newOpen);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  const propertyIds = item?.properties?.map((prop) => prop.property_id);

  return (
    <div className="property-listing-item">
      <Box sx={{ width: '36%',paddingRight:'10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <img
              width={130}
              alt="profile"
              src={item?.profile_picture}
              style={{
                borderRadius: '20px',
                margin: '10px 0',
                marginRight: '10px',
                height: '120px',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#474747',
                  fontWeight: 700,
                  fontSize: '20px',
                }}
              >
                {item?.agent_name.length > 25
                  ? `${item?.agent_name.substring(0, 25)}...`
                  : item?.agent_name}
              </Typography>
              <img
                src={VREFYDTICK}
                alt="vrefyd"
                width={20}
                style={{ margin: '10px' }}
              />
            </Box>

            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'capitalize',
                fontWeight: 500,
                borderRadius: '8px',
                margin: '15px 0',
              }}
              onClick={() =>
                navigate(
                  `/profile?customer_id=${item.broker_id}&city=${city}&locality=${locality}&properties=${propertyIds}`
                )
              }
            >
              View profile
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            color: '#7D7D7D',
            fontSize: '14px',
            fontWeight: 400,
            margin: '8px 0',
            width:'90%'
          }}
        >
          {item?.agent_description.length > 100
            ? `${item?.agent_description.substring(0, 100)}...`
            : item?.agent_description}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <CustomChip
            icon={YEARSICON}
            text={`${item?.years_of_experience}+ Years`}
            color={'#E7EEEF'}
          />
          <CustomChip
            icon={DEALSICON}
            text={`${item?.closed_deals}+ Deals closed`}
            color={'#755FFE26'}
          />
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ opacity: 0.5 }}
        flexItem
      />
      <Box
        sx={{
          width: '33%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: 1,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
            }}
          >
            SPECIALIZATION
          </Typography>
          {item?.specialization?.length >0 ? <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            {item?.specialization?.map((item) => {
              return <CustomChip text={item} color={'#E7EEEF'} />;
            })}
          </Box>:<Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Typography sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',m:'10px auto',fontSize:'12px',color:'#474747'}}>No Specializations added </Typography>
            </Box>}
        </Box>
        {item?.area_of_operation && <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              mt: 1,
            }}
          >
            AREAS OF OPERATION(S)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {renderAreaOfOperatioChips(item?.area_of_operation)
              ?.slice(0, 4)
              .map((chip, index) => {
                return <CustomChip key={index} text={chip} color={'#F4F0DF'} />;
              })}
            {renderAreaOfOperatioChips(item?.area_of_operation)?.length > 4 && (
              <Typography color={'#000000'} sx={{ fontSize: '12px' }}>
                {' '}
                {`+${
                  renderAreaOfOperatioChips(item?.area_of_operation)?.length - 4
                }`}{' '}
              </Typography>
            )}
          </Box>
        </Box>}
      </Box>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ opacity: 0.5 }}
        flexItem
      />
      <Box sx={{ width: '34%', padding: '0 14px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight:400
            }}
          >
            {item?.properties?.length && <Box sx={{display:'inline-block', fontWeight:700, color:'#7B0DB4', marginRight:'5px'}}>{item?.properties?.length}</Box>}
            MATCHING LISTINGS
          </Typography>
          <Button
            sx={{
              fontSize: '12px',
              textTransform: 'none',
              textDecoration: 'underline',
            }}
            onClick={() =>
              navigate(
                `/profile?customer_id=${item.broker_id}&city=${city}&locality=${locality}&properties=${propertyIds}`
              )
            }
          >
            View All
          </Button>
        </Box>
        <Box sx={{ width: '100%', padding: '14px' }}>
          <div
            className="slider-container"
            style={{ margin: '5px auto', width: '100%' }}
          >
            <Slider ref={sliderRef} {...settings}>
              {item?.properties?.map((prop) => {
                return (
                  <Box
                    key={prop.property_id}
                    sx={{
                      padding: '2px',
                      borderRadius: '8px',
                      margin: '0',
                      position: 'relative',
                      overflow: 'hidden',
                      marginLeft: '10px',
                    }}
                  >
                    <Box
                      onClick={() =>
                        handlePropertyClick(prop.property_id, prop.broker_id)
                      }
                      sx={{
                        width: '100%',
                        height: '80%',
                        position: 'relative',
                        padding: '8px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        sx={{
                          height: '80%',
                          width: '100%',
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: '8px',
                        }}
                      >
                        {/* <img src={LOCK} style={{zIndex:200, position:'absolute',top:0,left:0,width:'100%',height:'100%'}}  /> */}

                        {/* <img src={LOCK} alt="lock" style={{width:'100%',height:'100%',position:'absolute'}}  /> */}

                        <img
                          width={'170px'}
                          height={'130px'}
                          style={{
                            borderRadius: '8px',
                            objectFit: 'cover',
                          }}
                          src={prop.cover_picture || DEFAULTIMG}
                          alt="img"
                          onError={(e) => {
                            e.target.src = DEFAULTIMG;
                          }}
                        />
                        <Typography
                          sx={{
                            position: 'absolute',
                            pl: '5px',
                            bottom: '0',
                            color: '#FFFFFF',
                            fontSize: '10px',
                            fontWeight: 600,
                          }}
                        >
                          {prop.locality}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography sx={{ fontSize: '13px' }}>
                          {prop.bedroom_count
                            ? `${prop.bedroom_count} bhk`
                            : ' '}
                        </Typography>
                        <Typography sx={{ fontSize: '13px' }}>
                          {numDifferentiation(
                            prop.property_sale_type === SALEVALUE
                              ? prop.sale_price
                              : prop.rent_amount
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Slider>
          </div>
        </Box>
      </Box>

      <PropertyDetail
        selectedPropertyID={selectedPropertyID}
        toggleDrawer={toggleDrawer}
        open={openPropertyDetail}
        setOpen={setOpenPropertyDetail}
        from={'home'}
        // fetchListedProperties={fetchListedProperties}
        // setOpenProfile={setOpenProfileDetails}
      />
      <ProfileDetail
        customer_id={selectedBrokerProfile}
        toggleDrawer={toggleProfileDrawer}
        open={openProfileDetails}
        setOpen={setOpenProfileDetails}
      />
    </div>
  );
};

export default PropertyListingItem;
