import axios from 'axios';
import { LOCAL_STORAGE_DATA } from '../constants/app-constants';
import { expireSession } from '../Utils/helperUtils';
import qs from 'qs';
var internals = {};

const API_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_URL, 'API_URL');

internals.requestServer = async function (
  endPoint,
  data,
  method,
  headers,
  contentType,
  auth,
  refresh_token = false
) {
  try {
    let defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': contentType || 'application/json',
    };
    if (auth) {
      defaultHeaders['Authorization'] = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_DATA.ACCESS_TOKEN
      )}`;
    } else if (refresh_token) {
      defaultHeaders['Authorization'] = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_DATA.REFRESH_TOKEN
      )}`;
    }
    let params = {
      method: method || 'GET',
      headers: Object.assign(headers || {}, defaultHeaders),
    };

    if (data) {
      if (params.method === 'GET') {
        endPoint +=
          (endPoint.indexOf('?') > -1 ? '&' : '?') + qs.stringify(data);
      } else {
        params.data = data;
      }
    }
    params.url = `${API_URL}${endPoint}`;

    return axios(params)
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if(error?.status===401){
          //handle refresh token
          localStorage.clear()
          window.location.replace("/");
        }
        return error;
      });
  } catch (error) {
    expireSession();
  }
};

export default internals.requestServer;
