import React, { useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SignupLoginForm from "./Pages/SignupLogin/SignupLoginForm";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import {
  LOGIN,
  VERIFY_OTP,
  OTP_SUCCESS,
  CHAT_WITH_AIRA,
  FOUR_0_FOUR,
  HOME,
  UPDATE_DETAILS,
  MESSAGES,
  DASHBOARD,
  ADD_PROPERTY,
  PROFILE_ONBOARDING,
  IMAGE_VERIFICATION,
  CREATE_PROFILE,
  INITIATE_EKYC,
  DECLARATION,
  PROPERTY_DETAILS,
  PROFILE_PREVIEW,
} from "./constants/route-path";
import LoaderComponent from "./components/loader";
import ErrorBoundary from "./components/errorBoundary";
import Protected from "./Utils/protectedRoute";
import { loginActions } from "./Store/loginSlice";
import Navbar from "./components/navbar";
import LocationChangeListener from "./components/ LocationChangeListener";
import { userActions } from "./Store/userSlice";
import ViewProfile from "./Pages/Broker/ViewProfile";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebaseUtils";
import PushNotification from "./components/PushNotification";
import PropertyListing from "./Pages/PropertyListing/propertyListing";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apis from "./Services/api-services";

const ChatWindow = React.lazy(() => import("./Pages/ChatWindow/ChatWindow"));
const OTPVerificationForm = React.lazy(() =>
  import("./Pages/OTPVerification/OTPVerificationForm")
);
const SuccessScreen = React.lazy(() =>
  import("./Pages/OTPSuccess/SuccessScreen")
);

const Messages = React.lazy(() => import("./Pages/Messages/messages"));
const Home = React.lazy(() => import("./Pages/Home/home"));

const HomeNew = React.lazy(() => import("./Pages/Home-new/home-new"));
const Dashboard = React.lazy(() => import("./Pages/Dashboard/dashboard"));

const UpdateDetails = React.lazy(() =>
  import("./Pages/UpdateDetails/updateDetails")
);
const AddProperty = React.lazy(() => import("./Pages/AddProperty"));

const ProfileOnboarding = React.lazy(() =>
  import("./Pages/BrokerBenefits/BrokerBenefits")
);
const CreateProfile = React.lazy(() => import("./Pages/Broker/CreateProfile"));
const ImageVerification = React.lazy(() =>
  import("./Pages/Broker/ImageVerification")
);
const InitiateEKYC = React.lazy(() => import("./Pages/Broker/InitiateEKYC"));
const Declaration = React.lazy(() => import("./Pages/Broker/Declaration"));
const PropertyDetails = React.lazy(() => import("./Pages/PropertyDetails"));
const ProfilePreview = React.lazy(() => import("./Pages/ProfilePreview"));

const NotFound = React.lazy(() => import("./components/notFound"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#600DB4",
      paper: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      xmd: 788,
      md: 1000,
      lg: 1150,
      xl: 1536,
    },
  },
});

const App = () => {
  const NOT_ALLOWED_NAVBAR = [
    FOUR_0_FOUR,
    LOGIN,
    UPDATE_DETAILS,
    VERIFY_OTP,
    OTP_SUCCESS,
    CHAT_WITH_AIRA,
  ];
  const [selectedChat] = useState(null);
  const [chats] = useState([]);
  const [curPath, setCurPath] = useState("");
  const [selectedNavLink, setSelectedNavLink] = useState(1);
  const [notifications, setNotifications] = useState([]);

  const { isLoggedIn } = useSelector((state) => state.login || {});

  const dispatch = useDispatch();

  const handlePathChange = (path) => {
    if (path === DASHBOARD) {
      setSelectedNavLink(2);
    } else if (path === HOME) {
      setSelectedNavLink(1);
    } else if (path === MESSAGES) {
      setSelectedNavLink(3);
    } else {
      setSelectedNavLink(0);
    }

    setCurPath(path);
  };

  const getNotifications = async () => {
    const response = await apis.notification.getNotifications();
    setNotifications(response?.data?.notifications || []);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(userActions.clearUserData());
    dispatch(loginActions.loggedOut());
    window.location.replace(LOGIN);
  };

  const handleNewMessage = (message, chatTitle) => {};

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);

    const audio = new Audio("../notification-bell.wav");
    audio.play();

    toast.info(<PushNotification notification={payload.notification} />, {
      position: "top-right",
      autoClose: true,
      hideProgressBar: false,
      progressStyle: { background: "#600DB4" },
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      style: { lineHeight: 1.4 },
      onClose: () => toast.done(),
      icon: () => (
        <img
          style={{ width: "20px", height: "20px" }}
          src="./fav-icon-vrefyd.jpg"
          alt="icon"
        />
      ),
    });
    getNotifications();
  });

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoaderComponent />}>
        <ErrorBoundary>
          <Router>
            <LocationChangeListener handlePathChange={handlePathChange} />
            {!NOT_ALLOWED_NAVBAR.includes(curPath) && (
              <Navbar
                handleLogout={handleLogout}
                selectedNavLink={selectedNavLink}
                setSelectedNavLink={setSelectedNavLink}
                setNotifications={setNotifications}
                notifications={notifications}
              />
            )}

            <ToastContainer />

            <Routes>
              <Route
                path={LOGIN}
                element={
                  !isLoggedIn ? (
                    <SignupLoginForm />
                  ) : (
                    <Navigate to={HOME} replace />
                  )
                }
              />
              <Route
                path={MESSAGES}
                element={
                  <Protected>
                    <Messages />
                  </Protected>
                }
              />
              <Route
                path={DASHBOARD}
                element={
                  <Protected>
                    <Dashboard />
                  </Protected>
                }
              />
              <Route
                path={HOME}
                element={
                  <Protected>
                    {/* <Home /> */}
                    <HomeNew />
                  </Protected>
                }
              />
              <Route
                path={VERIFY_OTP}
                element={
                  !isLoggedIn ? (
                    <OTPVerificationForm />
                  ) : (
                    <Navigate to={HOME} replace />
                  )
                }
              />
              <Route path={OTP_SUCCESS} element={<SuccessScreen />} />
              <Route
                path={UPDATE_DETAILS}
                element={
                  <Protected>
                    <UpdateDetails />
                  </Protected>
                }
              />
              <Route
                path={CHAT_WITH_AIRA}
                element={
                  <Protected>
                    <ChatWindow
                      selectedChat={selectedChat}
                      chats={chats}
                      onNewMessage={handleNewMessage}
                    />
                  </Protected>
                }
              />
              <Route
                path={ADD_PROPERTY}
                element={
                  <Protected>
                    <AddProperty />
                  </Protected>
                }
              />
              <Route
                path="/profile"
                element={
                  <Protected>
                    <ViewProfile />
                  </Protected>
                }
              />
              <Route
                path={PROFILE_ONBOARDING}
                element={
                  <Protected>
                    <ProfileOnboarding />
                  </Protected>
                }
              />
              <Route
                path={CREATE_PROFILE}
                element={
                  <Protected>
                    <CreateProfile />
                  </Protected>
                }
              />

              <Route
                path={IMAGE_VERIFICATION}
                element={
                  <Protected>
                    <ImageVerification />
                  </Protected>
                }
              />
              <Route
                path={DECLARATION}
                element={
                  <Protected>
                    <Declaration />
                  </Protected>
                }
              />
              <Route
                path={INITIATE_EKYC}
                element={
                  <Protected>
                    <InitiateEKYC />
                  </Protected>
                }
              />
              <Route
                path={PROPERTY_DETAILS}
                element={
                  <Protected>
                    <PropertyDetails />
                  </Protected>
                }
              />
              <Route
                path={PROFILE_PREVIEW}
                element={
                  <Protected>
                    <ProfilePreview />
                  </Protected>
                }
              />
              <Route path={FOUR_0_FOUR} element={<NotFound />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
